// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {updateContent} from '@github-ui/updatable-content'

on('click', '.js-expandable-team-breadcrumbs .js-team-breadcrumb-trigger', async function (event) {
  const container = (event.target as Element).closest<HTMLElement>('.js-expandable-team-breadcrumbs')!
  container.classList.add('is-loading')
  try {
    await updateContent(container)
  } catch {
    // Do nothing.
  }
  container.classList.remove('is-loading')
})
