/* eslint i18n-text/no-en: off */
import type AutocompleteElement from '@github/auto-complete-element'
import type DetailsDialogElement from '@github/details-dialog-element'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function orgTwoFactorInput(): HTMLInputElement {
  return document.querySelector<HTMLInputElement>('.js-org-enable-two-factor')!
}

function twoFactorSecureMethodsPresent(): boolean {
  return document.querySelector<HTMLInputElement>('.js-org-two-factor-secure-methods-currently-enabled') != null
}

function enablingTwoFactor(): boolean {
  const twoFactorCurrentlyEnabled =
    document.querySelector<HTMLInputElement>('.js-org-two-factor-currently-enabled')!.value === '0'
  return orgTwoFactorInput().checked && twoFactorCurrentlyEnabled
}

function twoFactorRequirementSettingChanged(): boolean {
  const enabled = document.querySelector<HTMLInputElement>('.js-org-two-factor-currently-enabled')!.value === '1'
  const enabling = orgTwoFactorInput().checked
  return (enabled && !enabling) || (!enabled && enabling)
}

function twoFactorRequirementSecureMethodSettingChanged(): boolean {
  const current = document.querySelector<HTMLInputElement>('.js-org-two-factor-secure-methods-currently-enabled')
  const checkbox = document.querySelector<HTMLInputElement>('.js-org-enable-two-factor-secure-methods')
  if (current == null || checkbox == null) {
    return false
  }

  const enabled = current.value === '1'
  const enabling = checkbox.checked
  return (enabled && !enabling) || (!enabled && enabling)
}

// update the 'submit' button state based on whether either of the two-factorsettings have changed
function updateTwoFactorRequirementSubmitButtonState() {
  const submitButton = document.querySelector<HTMLButtonElement>('.js-org-two-factor-submit-button')
  if (submitButton == null) {
    // no confirmation required, so dialog not rendered
    return
  }

  const disabled = !twoFactorRequirementSettingChanged() && !twoFactorRequirementSecureMethodSettingChanged()
  submitButton.setAttribute('aria-disabled', disabled.toString())
}

// Updates the two-factor requirement settings dialog. Because the dialog content is dependent on the state of the
// two nested checkboxes, we are unable to predict the dialog content on page load; rather we have to do it here
// whenever the state of either checkbox changes.
function updateTwoFactorConfirmationDialog() {
  if (!twoFactorSecureMethodsPresent()) {
    // if the secure methods checkbox isn't present, the dialog as rendered is correct
    return
  }

  const requirementChanged = twoFactorRequirementSettingChanged()
  const secureMethodsChanged = twoFactorRequirementSecureMethodSettingChanged()
  if (!requirementChanged && !secureMethodsChanged) {
    return
  }

  const requirementEnabled =
    document.querySelector<HTMLInputElement>('.js-org-two-factor-currently-enabled')!.value === '1'
  const secureMethodsEnabled =
    document.querySelector<HTMLInputElement>('.js-org-two-factor-secure-methods-currently-enabled')!.value === '1'

  const disablingRequirement = requirementEnabled && requirementChanged
  const enablingRequirement = !requirementEnabled && requirementChanged
  const disablingSecureMethods = secureMethodsEnabled && secureMethodsChanged
  const enablingSecureMethods = !secureMethodsEnabled && secureMethodsChanged

  const dialogTitle = document.querySelector<HTMLElement>('.js-org-two-factor-confirmation-dialog .Box-title')!
  const dialogButton = document.querySelector<HTMLElement>('.js-org-two-factor-confirmation-dialog-button')!
  const orgName = document.querySelector<HTMLInputElement>('#two-factor-org-name')!.value

  // hide all of the dialog contents so we can show the correct one depending on application/checkbox state
  for (const span of document.querySelectorAll<HTMLElement>('.js-org-two-factor-confirmation-dialog-body span')) {
    span.hidden = true
  }

  if (disablingRequirement) {
    // disabling the 2FA requirement completely
    dialogTitle.textContent = `Are you sure you want to remove the two-factor authentication requirement for the ${orgName} organization?`
    document.querySelector<HTMLElement>('#tfrc-disabling-requirement')!.hidden = false
    dialogButton.classList.remove('Button--primary')
    dialogButton.classList.add('Button--danger')
  } else if (enablingRequirement && enablingSecureMethods) {
    // enabling 2FA requirement with secure methods
    dialogTitle.textContent = `Are you sure you want to require two-factor authentication and only allow secure methods for all users in the ${orgName} organization?`
    document.querySelector<HTMLElement>('#tfrc-enabling-requirement-with-secure-methods')!.hidden = false
    dialogButton.classList.remove('Button--danger')
    dialogButton.classList.add('Button--primary')
  } else if (enablingRequirement) {
    // enabling 2FA requirement w/o secure methods
    dialogTitle.textContent = `Are you sure you want to require two-factor authentication for all users in the ${orgName} organization?`
    document.querySelector<HTMLElement>('#tfrc-enabling-requirement-without-secure-methods')!.hidden = false
    dialogButton.classList.remove('Button--danger')
    dialogButton.classList.add('Button--primary')
  } else if (disablingSecureMethods) {
    // disabling secure methods only
    dialogTitle.textContent = `Are you sure you want to allow all two-factor authentication methods for the ${orgName} organization?`
    document.querySelector<HTMLElement>('#tfrc-disabling-secure-methods-only')!.hidden = false
    dialogButton.classList.remove('Button--primary')
    dialogButton.classList.add('Button--danger')
  } else {
    // enabling secure methods only
    dialogTitle.textContent = `Are you sure you want to only allow secure methods of two-factor authentication for all users in the ${orgName} organization?`
    document.querySelector<HTMLElement>('#tfrc-enabling-secure-methods-only')!.hidden = false
    dialogButton.classList.remove('Button--danger')
    dialogButton.classList.add('Button--primary')
  }
}

// bypass showing the two-factor confirmation dialog if we're not enabling two-factor. always show the confirmation dialog
// if the new two-factor secure methods setting is not present.
on('click', '.js-org-two-factor-submit-button', function (event) {
  if (!twoFactorSecureMethodsPresent()) {
    // secure methods feature not enabled, only show confirmation when enabling requirement
    if (!enablingTwoFactor()) {
      event.preventDefault()
    }
  } else {
    // update the dialog content which depends on the state of the checkboxes
    updateTwoFactorConfirmationDialog()

    // secure methods feature enabled, always show confirmation if top-level 2FA requirement is changing
    if (!twoFactorRequirementSettingChanged() && !twoFactorRequirementSecureMethodSettingChanged()) {
      event.preventDefault()
    }
  }
})

on('change', '.js-org-enable-two-factor', function () {
  updateTwoFactorRequirementSubmitButtonState()

  const requirementCheckbox = document.querySelector<HTMLInputElement>('.js-org-enable-two-factor')
  const secureMethodsRow = document.querySelector<HTMLElement>('.js-two-factor-secure-methods')
  const warningBanner = document.querySelector<HTMLElement>('.js-org-two-factor-requirement-warning-banner')
  if (requirementCheckbox == null || secureMethodsRow == null || warningBanner == null) {
    // need to tolerate these being missing from the DOM if the feature hasn't been fully rolled out
    return
  }

  if (requirementCheckbox.checked) {
    warningBanner.hidden = false
    secureMethodsRow.hidden = false
  } else {
    warningBanner.hidden = true
    secureMethodsRow.hidden = true
  }
})

on('change', '.js-org-enable-two-factor-secure-methods', function () {
  updateTwoFactorRequirementSubmitButtonState()
})

// Controls dialog if the 2FA requirement feature flag isnt enabled. Can be removed once feature is fully rolled out
on('change', '.js-two-factor-needs-confirmation', function (event) {
  const currentTarget = event.currentTarget as HTMLInputElement
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-confirm-2fa-modal')!.classList.toggle('d-none', !currentTarget.checked)
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-2fa-save-button')!.classList.toggle('d-none', currentTarget.checked)
})
