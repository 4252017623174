// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'

on('click', '.js-team-search-filter', function (event) {
  event.preventDefault()

  const filter = event.currentTarget.getAttribute('data-filter')!
  const menu = event.currentTarget.closest<HTMLElement>('[data-filter-on]')!
  const filterOn = menu.getAttribute('data-filter-on')!
  const field = document.querySelector<HTMLInputElement>('.js-team-search-field')!

  // Remove any existing filters on the opened dropdown.
  const regex = new RegExp(`${filterOn}:[a-z]+`)
  const currentFilters = field.value.trim().replace(regex, '')

  field.value = `${currentFilters} ${filter}`.replace(/\s\s/, ' ')
  field.focus()
  fire(field, 'input')
})
