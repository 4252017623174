// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'

function formatCount(count: number): string {
  if (count > 999) {
    return `${(count / 1000).toFixed(1)}k`
  }

  return count.toLocaleString()
}

function setProfileTabCount(el: Element, count: number) {
  if (count > 0) {
    el.textContent = formatCount(count)
  } else {
    el.remove()
  }
}

remoteForm('.js-fetch-tab-count', async function (form, wants) {
  const result = await wants.json()
  const data = result.json
  for (const selector in data.selectors) {
    const element: Element = form.querySelector<HTMLElement>(selector)!
    setProfileTabCount(element, data.selectors[selector])
  }
})

observe('.js-fetch-tab-count', {
  constructor: HTMLFormElement,
  add: requestSubmit,
})
