import type AutocompleteElement from '@github/auto-complete-element'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onKey} from '@github-ui/onfocus'

// Don't let users accidentally submit the form when you hit enter
onKey('keydown', '.js-security-manager-team-add-search', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key === 'Enter') {
    event.preventDefault()
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

on('auto-complete-change', '.js-security-manager-team-add-auto-complete', function (event) {
  const autoComplete = event.target as AutocompleteElement
  if (!autoComplete.value) {
    return
  }

  const selectedTeam = autoComplete.querySelector<HTMLInputElement>('.js-security-manager-team-add-selected-team')
  selectedTeam!.value = autoComplete.value // copy chosen auto-complete value into hidden input
  autoComplete.value = '' // clear the search

  const form = autoComplete.closest('form') as HTMLFormElement
  form.submit()
})
