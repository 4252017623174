// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'

on('auto-check-success', '.js-new-organization-name', function (event) {
  const field = event.target as HTMLInputElement
  for (const trialLink of document.querySelectorAll<HTMLAnchorElement>('.js-enterprise-trial-link')) {
    updateTrialLink(trialLink, field.value)
  }
})

on('auto-check-success', '.js-new-organization-name', function (event) {
  const field = event.target as HTMLInputElement
  const container = field.closest<HTMLElement>('dd')!
  const hint = container.querySelector('.js-field-hint-name')
  if (!hint) {
    return
  }
  hint.textContent = field.value
})

observe('.js-hide-org-name-hint', function (event) {
  const warning = event.parentElement!
  if (warning) {
    warning.hidden = true
  }
})

function updateTrialLink(trialLink: HTMLAnchorElement, orgName: string) {
  const trialURL = new URL(trialLink.href, window.location.origin)
  const searchParams = new URLSearchParams(trialURL.search)

  searchParams.delete('organization_name')
  searchParams.delete('login')
  searchParams.append('organization_name', orgName)
  searchParams.append('login', getLogin())

  const newUrl = new URL(trialLink.href, window.location.origin)
  newUrl.search = searchParams.toString()

  trialLink.href = newUrl.toString()
}

function getLogin(): string {
  const loginField = document.querySelector<HTMLInputElement>('#organization_login')!
  return loginField.value
}

// Remove an added member and canceling invitations
remoteForm('.js-org-list-item .js-org-remove-item', async function (form, wants) {
  /* eslint-disable-next-line github/no-d-none */
  form.closest<HTMLElement>('.js-org-list-item')!.classList.add('d-none')
  try {
    await wants.text()
  } catch {
    /* eslint-disable-next-line github/no-d-none */
    form.closest<HTMLElement>('.js-org-list-item')!.classList.remove('d-none')
    const errorMessage = form.getAttribute('data-error-message')
    if (errorMessage) {
      alert(errorMessage)
      return
    }
  }

  form.closest<HTMLElement>('.js-org-list-item')!.remove()
})

on('click', '.js-plan-choice', event => {
  const signupContinueActions = document.querySelector('.SignUpContinueActions')
  const targetValue = (event.target as HTMLInputElement).value

  showCorrectPlanDetails(targetValue)

  if (signupContinueActions != null) {
    signupContinueActions.classList.remove('has-removed-contents')
  }

  const choiceIsDisabled = !(targetValue && targetValue === 'business_plus')
  for (const choice of document.querySelectorAll<HTMLInputElement>('.js-enterprise-choice')) {
    choice.disabled = choiceIsDisabled
  }
})

on('click', '.js-enterprise-choice', event => {
  const billingSection = document.querySelector('.js-billing-section')
  const signupContinueActions = document.querySelector('.SignUpContinueActions')
  const contactUsButton = document.querySelector('.js-enterprise-contact')
  const startTrialButton = document.querySelector('.js-enterprise-trial-link')
  const targetValue = (event.target as HTMLInputElement).value

  const billingElements = [billingSection, signupContinueActions]

  if (contactUsButton) {
    contactUsButton.classList.toggle('has-removed-contents', targetValue !== 'server')
  }
  if (startTrialButton) {
    startTrialButton.classList.toggle('has-removed-contents', targetValue === 'server')
  }

  if (targetValue === 'cloud-trial') {
    if (billingSection) {
      billingSection.classList.add('has-removed-contents')
    }
  } else {
    for (const el of billingElements) {
      if (el != null) {
        el.classList.toggle('has-removed-contents', targetValue !== 'cloud')
      }
    }
  }

  showCorrectPlanDetails('business_plus')
})

function showCorrectPlanDetails(chosenPlan: string) {
  for (const cell of document.querySelectorAll<HTMLElement>('.js-plan-info')) {
    const planClass = classNameForPlan(chosenPlan)
    if (cell.classList.contains(planClass!)) {
      cell.classList.remove('has-removed-contents')
      cell.hidden = false
    } else {
      cell.classList.add('has-removed-contents')
      cell.hidden = true
    }
  }
}

function classNameForPlan(plan: string): string | null {
  if (plan === 'business_plus') {
    return classNameForEnterprisePlan()
  } else if (plan === 'free') {
    return 'js-free-plan-info'
  } else if (plan === 'business') {
    return 'js-business-plan-info'
  }
  return null
}

function classNameForEnterprisePlan(): string {
  const enterpriseType = document.querySelector<HTMLInputElement>('.js-enterprise-choice:checked')!
  if (enterpriseType.value === 'server') {
    return 'js-enterprise-server-plan-info'
  } else if (enterpriseType.value === 'cloud-trial') {
    return 'js-enterprise-cloud-trial-plan-info'
  } else {
    return 'js-enterprise-cloud-plan-info'
  }
}

// New Redesign

// Show "Please specify your industry" custom input on selection of "Other"
on('change', '.js-enterprise-trial-industry', function (event) {
  const target = event.target as HTMLSelectElement

  const selectedIndustry = target.value

  const otherIndustryField = document.querySelector<HTMLElement>('.js-enterprise-trial-other-industry-field')
  if (otherIndustryField) {
    otherIndustryField.hidden = selectedIndustry !== otherIndustryField.getAttribute('data-trigger-option-value')!
  }
})

on(
  'details-menu-selected',
  '.js-org-selector',
  function (event) {
    const summary = document.querySelector('#details-summary')!
    const orgNameField = document.querySelector<HTMLInputElement>('#org-name-input')!
    const orgInput = document.querySelector<HTMLInputElement>('#organization_profile_name')!
    const selected = event.detail.relatedTarget
    const selectedData = selected.getAttribute('data-context-org')
    const orgExistInput = document.querySelector<HTMLInputElement>('#org-exists-input')!

    summary.innerHTML = selected.innerHTML
    if (selectedData) {
      orgInput.setAttribute('value', selectedData)
      orgNameField.hidden = true
      orgExistInput.setAttribute('value', 'true')
    } else {
      orgInput.removeAttribute('value')
      orgNameField.hidden = false
      orgExistInput.setAttribute('value', 'false')
    }
  },
  {capture: true},
)
