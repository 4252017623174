import {controller, target, attr} from '@github/catalyst'
import {eventToHotkeyString} from '@github-ui/hotkey'
import {hasInteractions} from '@github-ui/has-interactions'

@controller
class RecommendedMemexProjectsElement extends HTMLElement {
  @target form: HTMLFormElement
  @target limitNotice: HTMLElement
  @target input: HTMLInputElement
  @target list: HTMLElement
  @target submitButton: HTMLButtonElement
  @target clearButton: HTMLButtonElement
  @target filterEmptyState: HTMLElement

  @attr query = ''

  get items() {
    return Array.from(this.list.children) as HTMLElement[]
  }

  async formModified() {
    this.submitButton.disabled = !hasInteractions(this.form)
  }

  // Prevent more than n pinned repository selections.
  async limitPins() {
    const checkboxes = Array.from(this.form.querySelectorAll<HTMLInputElement>('input[type="checkbox"]'))

    const used = checkboxes.filter(el => el.checked).length
    const limit = parseInt(this.getAttribute('max')!, 10)

    for (const el of checkboxes) {
      el.disabled = used === limit && !el.checked
    }

    const label = this.limitNotice.getAttribute('data-remaining-label') || ''
    const diff = limit - used
    this.limitNotice.textContent = `${used} ${label}`
    this.limitNotice.classList.toggle('color-fg-danger', diff < 1)
  }

  filterShownItems(query: string) {
    for (const item of this.items) {
      const name = item.textContent || ''
      item.hidden = !name.toLowerCase().includes(query.toLowerCase())
    }

    this.filterEmptyState.hidden = this.items.some(item => !item.hidden)
  }

  handleSearchInput(evt: KeyboardEvent) {
    if (eventToHotkeyString(evt) === 'Escape') {
      evt.stopImmediatePropagation()
    }

    this.query = (evt.target as HTMLInputElement).value
    this.clearButton.hidden = this.query.length === 0

    this.filterShownItems(this.query)
  }

  handleClearSearch() {
    this.input.value = ''
    this.query = ''
    this.clearButton.hidden = true
    this.filterShownItems(this.query)
  }
}
