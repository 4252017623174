// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function groupInList(groupID: string, list: HTMLElement): boolean {
  return list.querySelector(`input[name="team[group_mappings][${groupID}][name]"]`) != null
}

function externalGroupInList(groupID: string, list: HTMLElement): boolean {
  return list.querySelector(`input[name="team[external_group_team][${groupID}][external_id]"]`) != null
}

function hideListContainer(hiddenState: boolean) {
  const listContainer = document.querySelector<HTMLElement>('.js-selected-group-mappings-container')!
  listContainer.hidden = hiddenState
}

function hideExternalListContainer(hiddenState: boolean) {
  const listContainer = document.querySelector<HTMLElement>('.js-selected-external-group-container')!
  listContainer.hidden = hiddenState
}

function getWarningMessage(external_group_name: string, target: HTMLElement): string | null {
  let warning = null

  if (target.hasAttribute('external-group-guest-collaborator-warning')) {
    warning = `${external_group_name} contains guest collaborators. Adding a guest collaborator to this organization will grant them the ability to see all <a href="https://docs.github.com/enterprise-cloud@latest/repositories/creating-and-managing-repositories/about-repositories#about-internal-repositories" target="_blank" rel="noopener noreferrer">internal repositories</a> that are owned by this organization.`
  } else if (target.hasAttribute('external-group-guest-collaborator-warning-no-permission')) {
    warning = `${external_group_name} contains guest collaborators. Because the current organization <a href="https://docs.github.com/en/organizations/managing-user-access-to-your-organizations-repositories/managing-repository-roles/setting-base-permissions-for-an-organization" target="_blank" rel="noopener noreferrer">base permission</a> is set to "No permission", adding a guest collaborator to this organization will not grant them the ability to see <a href="https://docs.github.com/enterprise-cloud@latest/repositories/creating-and-managing-repositories/about-repositories#about-internal-repositories" target="_blank" rel="noopener noreferrer">internal repositories</a> that are owned by this organization.`
  }

  return warning
}

function showExternalGroupGuestCollaboratorWarning(target: HTMLButtonElement, external_group_name: string) {
  if (!external_group_name) return
  const warning = document.querySelector<HTMLElement>('.js-external-group-guest-collaborator-warning')
  if (!warning) return

  const warningMessage = getWarningMessage(external_group_name, target)
  if (warningMessage != null) {
    warning.innerHTML = warningMessage
    warning.hidden = false
  } else {
    warning.hidden = true
  }
}

on(
  'details-menu-selected',
  '.js-team-group-mapping-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const id = target.getAttribute('data-id')!
    const name = target.getAttribute('data-name')!
    const description = target.getAttribute('data-description')!

    const template = document.querySelector<HTMLTemplateElement>('.js-team-group-mapping-template')!
    const clone = document.importNode(template.content, true)

    const nameInput = clone.querySelector<HTMLInputElement>('.js-group-mapping-hidden-name')!
    const descInput = clone.querySelector<HTMLInputElement>('.js-group-mapping-hidden-description')!
    const removeButton = clone.querySelector<HTMLButtonElement>('.js-remove-group-mapping')!

    nameInput.name = nameInput.name.replace('{GROUP_ID}', id)
    descInput.name = descInput.name.replace('{GROUP_ID}', id)
    removeButton.ariaLabel = removeButton.ariaLabel ? removeButton.ariaLabel.replace('{GROUP_NAME}', name) : null

    nameInput.value = name
    descInput.value = description

    clone.querySelector<HTMLElement>('.js-group-mapping-name')!.textContent = name
    clone.querySelector<HTMLElement>('.js-group-mapping-desc')!.textContent = description

    const list = document.querySelector<HTMLElement>('.js-selected-group-mappings')!

    if (!groupInList(id, list)) {
      list.append(clone)
      hideListContainer(false)
    }
  },
  {capture: true},
)

on(
  'details-menu-selected',
  '.js-external-group-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const id = target.getAttribute('data-id')!
    const display_name = target.getAttribute('data-display-name')!

    const template = document.querySelector<HTMLTemplateElement>('.js-external-group-template')!
    const clone = document.importNode(template.content, true)

    const removeButton = clone.querySelector<HTMLButtonElement>('.js-remove-external-group')!
    const displayNameInput = clone.querySelector<HTMLInputElement>('.js-external-group-hidden-display-name')!
    displayNameInput.name = displayNameInput.name.replace('{GROUP_ID}', id)

    removeButton.ariaLabel = removeButton.ariaLabel
      ? removeButton.ariaLabel.replace('{GROUP_NAME}', display_name)
      : null

    displayNameInput.value = display_name

    clone.querySelector<HTMLElement>('.js-external-group-display-name')!.textContent = display_name

    const list = document.querySelector<HTMLElement>('.js-selected-external-group')!

    list.textContent = ''

    if (!externalGroupInList(id, list)) {
      list.append(clone)
      hideExternalListContainer(false)
    }

    showExternalGroupGuestCollaboratorWarning(target, display_name)
  },
  {capture: true},
)

on('click', '.js-remove-group-mapping', function (event) {
  event.currentTarget.closest<HTMLElement>('.js-group-mapping-container')!.remove()

  const hasItem = !!document.querySelector('.js-selected-group-mappings .js-group-mapping-container')
  if (!hasItem) {
    hideListContainer(true)
  }
})

on('click', '.js-remove-external-group', function (event) {
  event.currentTarget.closest<HTMLElement>('.js-external-group-container')!.remove()

  const hasItem = !!document.querySelector('.js-selected-external-group .js-external-group-container')
  if (!hasItem) {
    hideExternalListContainer(true)
  }

  const warning = document.querySelector<HTMLElement>('.js-external-group-guest-collaborator-warning')
  if (warning) {
    warning.hidden = true
  }
})

observe('.js-team-group-mapping-menu', function (el) {
  const filterableInput = el.querySelector<HTMLElement>('remote-input')!
  const results = document.getElementById(filterableInput.getAttribute('aria-owns')!)
  if (!results) return
  filterableInput.addEventListener('error', function () {
    results.classList.add('is-error')
  })
})

observe('.js-external-group-menu', function (el) {
  const filterableInput = el.querySelector<HTMLElement>('remote-input')!
  const results = document.getElementById(filterableInput.getAttribute('aria-owns')!)
  if (!results) return
  filterableInput.addEventListener('error', function () {
    results.classList.add('is-error')
  })
})
