import {remoteForm} from '@github/remote-form'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function updatePerSeatInfo(response: {[key: string]: any}) {
  for (const selector in response.selectors) {
    const text = response.selectors[selector]
    const element = document.querySelector(selector)
    if (text && element) element.textContent = text
  }

  const atSeatLimit = response.filled_seats_percent === 100
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-need-more-seats')!.classList.toggle('d-none', !atSeatLimit)
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-add-org-member-form')!.classList.toggle('d-none', atSeatLimit)
}

remoteForm('.js-per-seat-invite-field, .js-per-seat-invite .js-org-remove-item', async function (form, wants) {
  const response = await wants.json()
  updatePerSeatInfo(response.json)
})
