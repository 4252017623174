import {dialog} from '@github-ui/details-dialog'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function didParentChange(nameField: HTMLInputElement): boolean {
  return nameField.value !== nameField.getAttribute('data-original-team-name')
}

function didVisibilityChange(originalVisibilityField: Element | null, secretRadio: HTMLInputElement): boolean {
  const isSecretOrNew = ['', 'SECRET']

  return (
    originalVisibilityField != null &&
    secretRadio.checked &&
    !isSecretOrNew.includes(originalVisibilityField.getAttribute('data-original-team-visibility')!)
  )
}

function ensureAcceptableVisibility(target: Element) {
  const form = target.closest<HTMLFormElement>('form')!
  const teamIdInput = form.querySelector('.js-selected-team-id')
  const id = teamIdInput instanceof HTMLInputElement ? teamIdInput.value : false
  const closed = form.querySelector<HTMLElement>('.js-team-privacy-closed')!
  const secret = form.querySelector<HTMLElement>('.js-team-privacy-secret')!
  const closedRadio = closed.querySelector<HTMLInputElement>('input')!
  const secretRadio = secret.querySelector<HTMLInputElement>('input')!

  secret.classList.toggle('color-fg-muted', !!id)
  secretRadio.disabled = !!id
  if (id) closedRadio.checked = true
}

observe('.js-select-team-menu', function (el: Element) {
  ensureAcceptableVisibility(el)
})

// Synchronize selected team menu item with hidden inputs.
on(
  'details-menu-selected',
  '.js-select-team-menu',
  function (event) {
    const menu = event.target as Element
    const item = event.detail.relatedTarget as HTMLButtonElement
    menu.querySelector<HTMLInputElement>('.js-selected-team-id')!.value = item.value
    menu.querySelector<HTMLInputElement>('.js-already-selected-team-name')!.value = item.getAttribute('data-team-name')!
    ensureAcceptableVisibility(menu)
  },
  {capture: true},
)

on('click', '.js-create-team-button', function (event) {
  const form = event.currentTarget.closest<HTMLElement>('form')!
  const nameField = form.querySelector('.js-already-selected-team-name')
  if (!(nameField instanceof HTMLInputElement)) {
    return
  }
  const originalVisibilityField = form.querySelector('.js-original-team-visibility')
  const secret = form.querySelector('.js-team-privacy-secret')
  if (!secret) {
    return
  }
  const secretRadio = secret.querySelector<HTMLInputElement>('input')!
  const parentChanged = didParentChange(nameField)
  const visibilityChanged = didVisibilityChange(originalVisibilityField, secretRadio)

  if (parentChanged || visibilityChanged) {
    event.preventDefault()

    const baseUrl = form.getAttribute('data-important-changes-summary-url')!
    const url = new URL(baseUrl, window.location.origin)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('parent_team', nameField.value)

    if (visibilityChanged) {
      params.append('visibility_changed', visibilityChanged.toString())
    }

    if (parentChanged) {
      params.append('parent_changed', parentChanged.toString())
    }

    url.search = params.toString()
    dialog({
      content: fetchSafeDocumentFragment(document, url.toString()),
      dialogClass: 'p-3',
    })
  }
})

// TODO Replace with <button form=""> attribute?
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-form
on('click', '.js-confirm-edit-team', function () {
  document.querySelector<HTMLFormElement>('.js-org-team-form')!.submit()
})
