// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Places the selected item from countries dropdown list into the location field
on('click', '.js-org-country-item', function (event) {
  const target = event.currentTarget as HTMLElement
  const value = target.textContent ? target.textContent : ''
  updateLocation(value, value)
})

// Sets location field to empty string
on('click', '.js-org-country-clear', function (event) {
  const target = event.currentTarget as HTMLElement
  const defaultTextString = target.getAttribute('data-default-message')
  if (defaultTextString) {
    updateLocation(defaultTextString, '')
  }
})

function updateLocation(displayText: string, locationValue: string) {
  const hiddenLocationField = document.querySelector<HTMLElement>('.js-org-location-input-hidden')
  const visibleLocationField = document.querySelector<HTMLElement>('.js-org-location-input')
  if (visibleLocationField && hiddenLocationField) {
    hiddenLocationField.setAttribute('value', locationValue)
    visibleLocationField.textContent = displayText
  }
  const selectMenuDropdown = document.querySelector<HTMLElement>('.js-dropdown')
  if (selectMenuDropdown) {
    selectMenuDropdown.toggleAttribute('open')
  }
}
