// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import type AutocompleteElement from '@github/auto-complete-element'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {parseHTML} from '@github-ui/parse-html'
import {remoteForm} from '@github/remote-form'

on('click', '.js-membership-tab', function (event) {
  const filter = event.currentTarget.getAttribute('data-membership')!
  const field = document.querySelector<HTMLInputElement>('.js-member-filter-field')!
  const filterOn = 'membership'

  // Remove any existing filters on the opened dropdown.
  const currentValue = field.value
  const regex = new RegExp(`${filterOn}:[a-z-]+`)
  const currentFilters = currentValue.toString().trim().replace(regex, '')

  field.value = `${currentFilters} ${filter}`.replace(/\s\s/, ' ').trim()
  field.focus()
  fire(field, 'input')

  const selectedTab = document.querySelector<HTMLElement>('.js-membership-tabs')!
  selectedTab.classList.remove('selected')
  event.currentTarget.classList.add('selected')
})

on('submit', '.js-needs-interstitial .js-approve-membership-request', function (event) {
  const form = event.currentTarget as HTMLFormElement

  const name = form.querySelector<HTMLElement>('[data-member-name]')!.getAttribute('data-member-name')!
  const actionType = form.getAttribute('data-action-type')!
  const container = form.closest<HTMLElement>('.js-add-members-container')!

  event.preventDefault()
  fetchGrantedRepositories(container, name, actionType, null)
})

on('submit', '.js-needs-interstitial.js-add-team-member-form', function (event) {
  const form = event.currentTarget as HTMLFormElement

  const member = form.elements.namedItem('member') as HTMLInputElement
  if (!member.value) return

  const actionType = form.getAttribute('data-action-type')!
  const container = document.querySelector<HTMLElement>('.js-add-members-container')!

  event.preventDefault()
  const returnTo = form.getAttribute('data-return-to')
  fetchGrantedRepositories(container, member.value, actionType, returnTo)
})

function getWarningMessage(name: string, user: HTMLElement): string | null {
  let warning = null

  if (user.hasAttribute('team-guest-collaborator-warning')) {
    warning = `${CSS.escape(
      name,
    )} is a guest collaborator. Adding a guest collaborator to this organization will grant them the ability to see all <a href="https://docs.github.com/enterprise-cloud@latest/repositories/creating-and-managing-repositories/about-repositories#about-internal-repositories" target="_blank" rel="noopener noreferrer">internal repositories</a> that are owned by this organization.`
  } else if (user.hasAttribute('team-guest-collaborator-warning-no-permission')) {
    warning = `${CSS.escape(
      name,
    )} is a guest collaborator. Because the current organization <a href="https://docs.github.com/en/organizations/managing-user-access-to-your-organizations-repositories/managing-repository-roles/setting-base-permissions-for-an-organization" target="_blank" rel="noopener noreferrer">base permission</a> is set to "No permission", adding a guest collaborator to this organization will not grant them the ability to see <a href="https://docs.github.com/enterprise-cloud@latest/repositories/creating-and-managing-repositories/about-repositories#about-internal-repositories" target="_blank" rel="noopener noreferrer">internal repositories</a> that are owned by this organization.`
  }

  return warning
}

// Determines whether to show the guest collaborator warning when adding a team member.
// The warning message element is in app/views/orgs/team_members/_manage_memberships_dialog.html.erb
on('auto-complete-change', '.js-add-team-member-form', (event: Event) => {
  const form = event.currentTarget as HTMLFormElement
  const warning = form.querySelector<HTMLElement>('.js-team-guest-collaborator-warning')
  if (!warning) return

  warning.hidden = true
  const completer = form.querySelector<AutocompleteElement>('auto-complete')!
  const name = completer.value
  if (!name) return

  const user = completer.querySelector<HTMLElement>(`[data-autocomplete-value="${name}"]`)
  if (!user) return

  const warningMessage = getWarningMessage(name, user)

  if (warningMessage != null) {
    warning.hidden = false
    warning.innerHTML = warningMessage
  } else {
    warning.hidden = true
  }
})

// Show the repositories that this member will gain access to when added to the team.
async function fetchGrantedRepositories(
  container: Element,
  memberName: string,
  actionType: string,
  returnTo: string | null,
) {
  const urlStr = container.getAttribute('data-url')!
  const url = new URL(urlStr, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('member', memberName)
  params.append('action_type', actionType)
  if (returnTo) {
    params.append('return_to', returnTo)
  }
  url.search = params.toString()

  toggleLoadingState(true)
  document.querySelector<HTMLElement>('.js-add-team-member-form')!.hidden = true
  const fragment = await fetchSafeDocumentFragment(document, url.toString())
  toggleLoadingState(false)
  container.textContent = ''
  container.appendChild(fragment)
}

function toggleLoadingState(isLoading: boolean) {
  document.querySelector<HTMLElement>('.js-add-members-loading-state')!.hidden = !isLoading
  document.querySelector<HTMLElement>('.js-add-members-modal-content')!.hidden = isLoading
}

// Insert new person into list of members.
remoteForm('.js-add-org-member-form', async function (form, wants) {
  const memberList = document.querySelector<HTMLElement>('.js-member-list')!
  form.classList.add('is-sending')

  const flashMessage = document.querySelector('.flash-messages')
  if (flashMessage) flashMessage.remove()

  let response
  try {
    response = await wants.json()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (!error.response && !error.response.json) return
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    memberList.insertAdjacentHTML('beforebegin', error.response.json.message_html || '')
    return
  }

  form.classList.remove('is-sending')
  form.querySelector<AutocompleteElement>('auto-complete')!.value = ''
  const element = parseHTML(document, response.json.list_item_html).querySelector<HTMLElement>('*')!
  const username = element.getAttribute('data-login')
  if (username) {
    for (const item of memberList.children) {
      if (item.getAttribute('data-login') === username) {
        item.remove()
        break
      }
    }
  }
  memberList.prepend(element)
})
