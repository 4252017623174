import {controller, targets} from '@github/catalyst'

@controller
class OrgsProjectsRoleSettingsElement extends HTMLElement {
  @targets items: HTMLElement[]
  private selectedItem: HTMLElement | null

  connectedCallback() {
    for (const item of this.items) {
      if (item.getAttribute('aria-checked') === 'true') {
        this.selectedItem = item
      }
    }
  }

  resetSelected() {
    for (const item of this.items) {
      if (item === this.selectedItem) {
        item.setAttribute('aria-checked', 'true')
      } else {
        item.setAttribute('aria-checked', 'false')
      }
    }
  }
}
