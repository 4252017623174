import type AutocompleteElement from '@github/auto-complete-element'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from '@github-ui/onfocus'
import {remoteForm} from '@github/remote-form'

onInput('.js-team-ldap-group-field', function (event) {
  ;(event.currentTarget as Element).closest<HTMLElement>('.form-group')!.classList.remove('errored')
})

on('auto-complete-change', '.js-import-ldap-group-completer', function (event) {
  const completer = event.target as AutocompleteElement

  const form = completer.closest<HTMLElement>('.js-ldap-group-adder')!
  const input = completer.querySelector<HTMLInputElement>('.js-team-ldap-group-field')!
  const dnInput = form.querySelector<HTMLInputElement>('.js-team-ldap-dn-field')!
  dnInput.value = ''

  if (completer.value) {
    const {dn, cn} = JSON.parse(completer.value)
    input.value = cn
    dnInput.value = dn
  }
})

remoteForm('.js-ldap-group-adder', async function (form, wants, request) {
  request.headers.set('X-Context', 'import')

  const container = form.closest<HTMLElement>('.js-ldap-import-groups-container')!

  let response
  try {
    response = await wants.html()
  } catch (e) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (e.response) {
      form.querySelector<HTMLElement>('.form-group')!.classList.add('errored')
    }
    return
  }

  const importFormActions = document.querySelector('.js-import-form-actions')
  container.classList.remove('is-empty')

  container.querySelector<HTMLElement>('.js-ldap-imported-groups')!.prepend(response.html)
  form.reset()
  form.querySelector<HTMLElement>('.js-team-ldap-group-field')!.focus()

  /* eslint-disable-next-line github/no-d-none */
  if (importFormActions) importFormActions.classList.remove('d-none')
})

// Hide group when removed
// Show blankslate, reset sumbit button if no groups remain in list
on('submit', '.js-team-remove-group', function (event) {
  event.currentTarget.closest<HTMLElement>('.js-team')!.classList.add('is-removing')
  document.querySelector<HTMLElement>('.js-team-ldap-group-field')!.focus()
})

// Actually remove group from DOM when ajax call succeeds
remoteForm('.js-team-remove-group', async function (form, wants) {
  try {
    await wants.text()
  } catch {
    form.closest<HTMLElement>('.js-team')!.classList.remove('is-removing')
    return
  }
  form.closest<HTMLElement>('.js-team')!.remove()
  if (!document.querySelector('.js-team:not(.is-removing)')) {
    document.querySelector<HTMLElement>('.js-ldap-import-groups-container')!.classList.add('is-empty')
    /* eslint-disable-next-line github/no-d-none */
    document.querySelector<HTMLElement>('.js-import-form-actions')!.classList.add('d-none')
  }
})

// Edit group
on('click', '.js-edit-team', function (event) {
  const team = event.currentTarget.closest<HTMLElement>('.js-team')!
  if (team.classList.contains('is-removing')) return

  team.classList.add('is-editing')
  team.querySelector<HTMLElement>('.js-team-name-field')!.focus()
})

// Cancel edits
on('click', '.js-cancel-team-edit', function (event) {
  const team = event.currentTarget.closest<HTMLElement>('.js-team')!
  const form = team.querySelector<HTMLFormElement>('.js-team-form')!
  team.classList.remove('is-editing')
  form.reset()
})

// TODO Replace with data-replace-remote-form behavior.
// Replace updated team row with replacement HTML
remoteForm('.js-team-form', async function (form, wants, request) {
  request.headers.set('X-Context', 'import')
  const response = await wants.html()
  const item = form.closest<HTMLElement>('.js-team')!
  item.replaceWith(response.html)
})
