import {fetchPoll} from '@github-ui/fetch-utils'
import {remoteForm, type Kicker} from '@github/remote-form'
import {showGlobalError} from '../behaviors/ajax-error'

/**
 * When the form submits, sets a loading state and then POSTs to create a new export job. Polls the url from the new job
 * until it's ready, finally redirecting the browser to the finished export results.
 */
async function exportEvents(loadingClass: string, menuClass: string, wants: Kicker, pollIntervalMs: number) {
  const loadingState = document.querySelector<HTMLElement>(loadingClass)!
  const menu = document.querySelector<HTMLDetailsElement>(menuClass)

  // Show/hide loading state. Set to `true` to hide menu and show loading state.
  function loading(toggle: boolean) {
    if (menu) {
      menu.hidden = toggle
      menu.open = false
    }
    loadingState.hidden = !toggle
  }

  loading(true)

  let response
  try {
    response = await wants.json()
  } catch {
    loading(false)
    showGlobalError()
    return
  }

  const data = response.json

  try {
    await fetchPoll(data.job_url, {}, pollIntervalMs)
  } catch {
    loading(false)
    showGlobalError()
    return
  }

  loading(false)

  window.location = data.export_url
}

remoteForm('.js-organization-members-export-form', async function (form, wants) {
  await exportEvents('.js-organization-members-exporting', '.js-organization-members-export-menu', wants, 2000)
})
