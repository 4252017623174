// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('click', '.js-org-discussion-toggle', (event: Event) => {
  const checkbox = event.currentTarget as HTMLInputElement
  const selectRepo = document.querySelector<HTMLElement>('.js-org-level-discussion-form')!
  const submitButton = document.querySelector<HTMLButtonElement>('#js-submit-button')!
  const repoSelected = document.getElementById('js-selected-repository-name')!

  if (submitButton) {
    if (checkbox.checked) {
      selectRepo.hidden = false
      if (repoSelected.textContent === 'Choose a repository') {
        submitButton.disabled = true
      }
    } else {
      selectRepo.hidden = true
      submitButton.disabled = false
    }
  }
})

on('click', '.js-org-level-repo-select-form input[name=repo_id]', async event => {
  const input = event.currentTarget as HTMLInputElement
  const label = document.getElementById('js-selected-repository-name')!

  const text = input.closest('.select-menu-item-text')!.querySelector('.js-repository-name')!.textContent
  const button = document.getElementById('js-submit-button') as HTMLButtonElement

  if (button) {
    button.disabled = false
  }

  if (text && label) {
    label.textContent = text
  }
})
