// controls show/hide of custom expiration field
// for organizations/:org/settings/personal-access-tokens
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('change', '.js-pat-expiration-limit', event => {
  const target = event.target as HTMLInputElement
  if (target.value === 'custom') {
    document.getElementById('custom-pat-expiration-limit-wrapper')?.removeAttribute('hidden')
  } else {
    document.getElementById('custom-pat-expiration-limit-wrapper')?.setAttribute('hidden', 'true')
  }
})
