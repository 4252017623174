import {changeValue} from '@github-ui/form-utils'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Show the mark all dropdown when the "Select all..." text is clicked
on('click', '.js-failed-invitations-mark-all', function () {
  document.querySelector<HTMLInputElement>('.js-failed-invitations-mark-all')!.hidden = true
  document.querySelector<HTMLInputElement>('.js-failed-invitations-unmark-all')!.hidden = false
  changeValue(document.querySelector<HTMLInputElement>('.js-failed-invitations-mark-all-checkbox')!, true)
  hideSelectedInvitationButtons(true)
  hideAllInvitationButtons(false)
  updateSelectedInvitationLabel()
})

// Clear the selection when the "Clear selection" text is clicked
on('click', '.js-failed-invitations-unmark-all', function () {
  document.querySelector<HTMLInputElement>('.js-failed-invitations-mark-all')!.hidden = true
  document.querySelector<HTMLInputElement>('.js-failed-invitations-unmark-all')!.hidden = true
  changeValue(document.querySelector<HTMLInputElement>('.js-failed-invitations-mark-all-checkbox')!, false)
  hideAllInvitationButtons(true)
  hideSelectedInvitationButtons(false)
})

// Whether to hide the "All invitations" buttons
function hideAllInvitationButtons(showAllButtons: boolean) {
  document.getElementById('retry_all_invitations_button')!.hidden = showAllButtons
  document.getElementById('delete_all_invitations_button')!.hidden = showAllButtons
}

// Whether to hide the "Selected invitations" buttons
function hideSelectedInvitationButtons(showInvitationButtons: boolean) {
  document.getElementById('retry_selected_invitations_button')!.hidden = showInvitationButtons
  document.getElementById('delete_selected_invitations_button')!.hidden = showInvitationButtons
}

function updateSelectedInvitationLabel() {
  const failedInvitationCount = document.getElementById('total-failed-invitation-count')!.getAttribute('value') || ''
  const currentInvitationElement = Array.from(document.getElementsByClassName('Button-label')).find(element => {
    return element.textContent?.includes('selected')
  })!
  const currentInvitationCount = currentInvitationElement.textContent || ''
  currentInvitationElement.textContent = currentInvitationCount.replace(/\d+/g, failedInvitationCount)
}
