// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'

remoteForm('.js-repo-topics-edit-form', async function (form, wants) {
  const container = form.closest<HTMLElement>('.js-topic-form-area')!

  // Remove error state when the user submits the topics form
  container.classList.remove('errored')
  container.querySelector<HTMLElement>('.js-topic-error')!.textContent = ''
  for (const el of container.querySelectorAll('.js-tag-input-tag.invalid-topic')) {
    el.classList.remove('invalid-topic')
  }

  try {
    await wants.json()
    flashNotice(form)
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    const data = error.response.json

    if (data.message) {
      container.classList.add('errored')
      container.querySelector<HTMLElement>('.js-topic-error')!.textContent = data.message
    }

    if (data.invalidTopics) {
      // Highlight the bad topics individually
      const inputs = form.querySelectorAll<HTMLInputElement>('.js-topic-input')
      for (const topic of data.invalidTopics) {
        for (const input of inputs) {
          if (input.value === topic) {
            const tag = input.closest<HTMLElement>('.js-tag-input-tag')!
            tag.classList.add('invalid-topic')
          }
        }
      }
    }
  }
})

// Submit the form when a user adds or removes a topic manually.
on('tags:changed', '.js-repo-topics-edit-form', function (event: Event) {
  const target = event.target as HTMLFormElement
  requestSubmit(target)
})

function flashNotice(form: Element) {
  const container = form.closest('.js-topic-save-notice-container')!
  const notice = container.querySelector('.js-repo-topics-save-notice')!
  /* eslint-disable-next-line github/no-d-none */
  notice.classList.remove('d-none')
  notice.classList.add('d-inline-block', 'anim-fade-in')

  setTimeout(() => {
    notice.classList.remove('d-inline-block')
    /* eslint-disable-next-line github/no-d-none */
    notice.classList.add('d-none')
  }, 1900)
}
